import React, { useCallback } from 'react';
import { useForm } from "react-hook-form";
import { Box, Checkbox, Field, Input, Label, Message, Text, Textarea } from 'theme-ui';
import { Event, jsonToEvent } from '../types';
import useAuthenticatedData from '../useAuthenticatedData';
import { endpoint } from '../lib/endpoint'
import { navigate } from 'gatsby';
import ActionButton from './ActionButton';

type OwnedEventEditFormProps = {
  eventId: string
};

export default ({ eventId }: OwnedEventEditFormProps) => {
  console.log('render form, eventId', eventId);
  const { register, handleSubmit, watch, formState: { errors, isValid, isSubmitting } } = useForm();

  const onSubmit = async (data: any) => {
    console.log('onSubmit', data);
    const formData = new FormData();
    const fields = ["title", "description", "date", "start_time", "end_time", "is_public"];
    for (const field of fields) {
      formData.append(field, data[field]);
    }
    if (data.image.length > 0) {
      formData.append("image", data.image[0]);
    }

    try {
      const result = await global.fetch(`${endpoint}/owned-events/${eventId}`, {
        method: 'PUT',
        credentials: 'include',
        mode: 'cors',
        body: formData
      });
      if (result.status > 201) {
        global.alert(`Oh no, this failed: ${result.statusText} - Please try again, or refresh the page.`);
      } else {
        navigate(`/owned-events/view/${eventId}`);
      }
    } catch (e) {
      console.error('ERR', e);
      global.alert(`Oh no, an error occurred: ${(e as Error).message || ''} - Please try again, or refresh.`);
    }

  };

  const { data, error, isLoading } =
    useAuthenticatedData<Event>(`/owned-events/${eventId}`, e =>
        jsonToEvent(JSON.parse(e).event));

  const refresh = useCallback(() => {
    location.reload();
  }, []);

  if (isLoading) {
    return <Message>Loading...</Message>
  }

  if (error) {
    return <Message>
      <Text>Please <a href='#' onClick={refresh}>refresh</a> to try again.</Text>
    </Message>
  }

  console.info('data', data);

  return <>
    <form onSubmit={handleSubmit(onSubmit)}>
      <Field label="Title" {...register("title")} defaultValue={data?.title} />
      <Label htmlFor="description">Description</Label>
      <Textarea rows={10} {...register("description")} defaultValue={data?.description} />
      <Label mt={3} htmlFor="image">Image (optional)</Label>
      <Input type="file" {...register("image")} accept="image/png, image/jpeg" />
      <Label mt={3} htmlFor="date">Which day will the event be?</Label>
      <Field label="Date" {...register("date")} type="date" defaultValue={data?.date} placeholder="Which day this event will be." mb={3} />
      <Label mt={3} htmlFor="start_time">What time will it start?</Label>
      <Field label="Start Time" {...register("start_time")} type="time" defaultValue={data?.start_time} placeholder="What time this event will be." mb={3} />
      <Label mt={3} htmlFor="end_time">And what time will it end?</Label>
      <Field label="End Time" {...register("end_time")} type="time" defaultValue={data?.end_time} mb={3} />
      <Label mt={3} htmlFor="is_public">If the event is public, it will be listed for anyone to find.</Label>
      <Box>
        <Label mb={3}>
          <Checkbox {...register("is_public")} defaultChecked={data?.is_public} />
          Public event?
        </Label>
      </Box>

      <ActionButton isLoading={isLoading} isSubmitting={isSubmitting} isValid={isValid}>
        {isSubmitting ? 'Updating...' : 'Submit'}
      </ActionButton>
    </form>
  </>
}
