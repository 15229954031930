import { useEffect, useState } from "react";
import useAuthenticatedFetch from './useAuthenticatedFetch';
import { endpoint } from './lib/endpoint'

export default function useAuthenticatedData<T>(path: string, convert: (body: string) => T) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [data, setData] = useState<T | undefined>(undefined);

  const [fetchAuthenticated, loggingIn] = useAuthenticatedFetch(`${endpoint}${path}`);

  useEffect(() => {
    if (!loading) {
      return;
    }
    fetchAuthenticated()
    .then(response => {
      console.log('response', response)
      if (!response.ok) {
        throw new Error('Response not ok'); // TODO: error handling
      }
      return response.text();
    })
    .then(body => {
      console.info('useAuthenticatedData, body', body);
      if (loading) {
        setData(convert(body));
        setLoading(false);
      }
    })
    .catch(setError);
    return () => setLoading(false);
  }, [loading, fetchAuthenticated]);

  return { isLoading: loading || loggingIn, error, data: data }
}
