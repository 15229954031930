import React from "react";
import { Heading, Text } from "theme-ui";
import { Helmet } from "react-helmet";
import Layout from "../../../components/Layout";
import LinkInApp from "../../../components/LinkInApp";
import OwnedEventEditForm from "../../../components/OwnedEventEditForm";
import MyEventsBreadcrumbs from "../../../components/MyEventsBreadcrumbs";

type EditEventParams = {
  params: {
    id: string
  }
}

export default ({ params }: EditEventParams) => {
  console.log('render owned events edit, params', params);
  const { id } = params;
  return (
    <Layout>
      <Helmet title="Edit My Event" />
      <MyEventsBreadcrumbs />
      <Heading>Edit My Event</Heading>
      <OwnedEventEditForm eventId={id} />
      <Text>
        <LinkInApp to={`/owned-events/view/${id}`}>Go back</LinkInApp> to the event.
      </Text>
      <Text pl={2}>
        <LinkInApp to={`/owned-events/${id}/archive`}>Archive</LinkInApp> this event.
      </Text>
    </Layout>
  )
}
