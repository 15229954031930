import React from "react";
import { Text } from "theme-ui";
import LinkInApp from './LinkInApp';

export default () => (
  <Text>
    <LinkInApp to="/">Home</LinkInApp>
    {" > "}
    <LinkInApp to="/owned-events">My Events</LinkInApp>
  </Text>
);

